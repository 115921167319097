define("@ember/template-compilation/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.__emberTemplateCompiler = void 0;
  _exports.__registerTemplateCompiler = __registerTemplateCompiler;
  _exports.precompileTemplate = _exports.compileTemplate = void 0;

  let __emberTemplateCompiler;

  _exports.__emberTemplateCompiler = __emberTemplateCompiler;

  const compileTemplate = function () {
    if (!__emberTemplateCompiler) {
      throw new Error('Attempted to call `compileTemplate` without first loading the runtime template compiler.');
    }

    return __emberTemplateCompiler.compile(...arguments);
  };

  _exports.compileTemplate = compileTemplate;
  let precompileTemplate;
  _exports.precompileTemplate = precompileTemplate;

  if (false
  /* DEBUG */
  ) {
    _exports.precompileTemplate = precompileTemplate = () => {
      throw new Error('Attempted to call `precompileTemplate` at runtime, but this API is meant to be used at compile time. You should use `compileTemplate` instead.');
    };
  }

  function __registerTemplateCompiler(c) {
    _exports.__emberTemplateCompiler = __emberTemplateCompiler = c;
  }
});