define("@ember/array/-internals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEmberArray = isEmberArray;
  _exports.setEmberArray = setEmberArray;
  const EMBER_ARRAYS = new WeakSet();

  function setEmberArray(obj) {
    EMBER_ARRAYS.add(obj);
  }

  function isEmberArray(obj) {
    return EMBER_ARRAYS.has(obj);
  }
});